import { useUrlSearchParams } from "use-url-search-params";
import { Redirect } from "react-router";
import Card from 'react-bootstrap/Card'
import { userMaintenance } from '../../helpers/APIHelper'
import React from 'react';
import Button  from 'react-bootstrap/Button'
import { Formik } from 'formik'
import { Form, Alert  } from 'react-bootstrap'
import { InputGroup } from 'react-bootstrap'
import * as yup from "yup";
import ErrorModal from '../../components/modals/ErrorModal'
import Bubbles from '../loading/Bubbles';

const ResetPassword = ({setAuthentication}) => {
    
    const [params, setParams] = useUrlSearchParams()

    const [modalShow, setModalShow] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false)
    const [isSuccess, setSuccess] = React.useState(false);

    const schema = yup.object().shape({
        email: yup.string().required("Email address is required."),
        password: yup.string().required("Password is required."),
        confirmPassword: yup.string().required("Confirm Password is required."),
        code: yup.string().required("Code is required."),
    });

    return (
        <div>
            {
                (params.token === undefined || params.token === null) &&
                    <Redirect to="/not-found" />
            }
            <Card className="ran-card">
                <Card.Header className="ran-card-header text-white">RESET PASSWORD</Card.Header>
                <Card.Body>
                    <>
                                {
                                    isSuccess &&
                                    <Alert variant="info" >
                                        Password has been successfully reset.
                                    </Alert>
                                }
                                <Formik
                                    initialValues={{
                                        email: "",
                                        password:"",
                                        confirmPassword: "",
                                        code: params.token
                                    }}
                                    onSubmit={async (values, {resetForm}) => {
                                        setLoading(true)
                                        setSuccess(false)
                                        
                                        var decodedString = Buffer.from(values.code, 'base64').toString('binary');
                                        values.code = decodedString;

                                        console.log(decodedString)

                                        var result = await userMaintenance('RESET_PASSWORD', values, setAuthentication);
                                        if(result !== null || result !== undefined){
                                            if(result.success) {
                                                setLoading(false)
                                                setSuccess(true)
                                                resetForm({
                                                    email: "",
                                                    password:"",
                                                    confirmPassword: "",
                                                    code:params.token
                                                })
                                            }
                                            else{
                                                // not found or bad request.
                                                if(result.status === 404 || result.status === 400){
                                                    setModalShow(true)
                                                    setErrors(result.errors)
                                                    setLoading(false)
                                                }
                                                // do nothing if unauthorize.
                                            }
                                        }
                                    }}
                                    validationSchema={schema}>
                                    {({
                                    errors,
                                    touched,
                                    handleBlur,
                                    handleChange,
                                    isSubmitting,
                                    values,
                                    handleSubmit
                                    }) => (
                                        <Form noValidate onSubmit={handleSubmit}>
                                            <Form.Group md="4" controlId="validateFormEmail">
                                                <Form.Label>Email address</Form.Label>
                                                <InputGroup hasValidation>
                                                    <Form.Control
                                                    type="text"
                                                    placeholder="Email address"
                                                    name="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.email}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                    {errors.email}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group md="4" controlId="validateFormPassword" className="mt-2">
                                                <Form.Label>Password</Form.Label>
                                                <InputGroup hasValidation>
                                                    <Form.Control
                                                    type="password"
                                                    placeholder="Password"
                                                    name="password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.password}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                    {errors.password}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group md="4" controlId="validateFormConfirmPassword" className="mt-2">
                                                <Form.Label>Confirm Password</Form.Label>
                                                <InputGroup hasValidation>
                                                    <Form.Control
                                                    type="password"
                                                    placeholder="Confirm Password"
                                                    name="confirmPassword"
                                                    value={values.confirmPassword}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.confirmPassword}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                    {errors.confirmPassword}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group md="4" controlId="validateFormCode" className="mt-2">
                                                <Form.Label></Form.Label>
                                                <InputGroup hasValidation>
                                                    <Form.Control
                                                    type="hidden"
                                                    placeholder="Code"
                                                    name="code"
                                                    value={values.code}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.code}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                    {errors.code}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                            {
                                                isLoading && 
                                                (
                                                    <div className="mt-3">
                                                        <Bubbles />
                                                    </div>
                                                )
                                            }
                                            <Form.Group  className="mt-4 mb-0">
                                                <Button  disabled={isLoading} type="submit" variant="warning" className="btn-danger text-white" style={{width: "100%"}}>
                                                                {
                                                                    isLoading ? 'PROCESSING' : 'RESET PASSWORD'
                                                                }
                                                </Button>
                                            </Form.Group>
                                    </Form>
                                    )}
                                </Formik>
                                <ErrorModal
                                    errors={errors}
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}/>
                            </>
                </Card.Body>
            </Card>
        </div>
    )
}

export default ResetPassword
