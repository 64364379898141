import React from 'react'
import Card from 'react-bootstrap/Card'
import { useState, useEffect } from 'react'
import { getTickets } from '../../../helpers/APIHelper'
import { Table, Button } from 'react-bootstrap'
import PaginationButton from '../../PaginationButton'
import Bubbles from '../../loading/Bubbles';
import { MdCreateNewFolder } from "react-icons/md";
import { Link } from 'react-router-dom';

const Tickets = ( {setAuthentication} ) => {

    const [isLoading, setLoading] = useState(true)
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({
        data : {}
    })

    useEffect(() => {
        async function fetchData() {
            await fetchInitialData();
        }
        fetchData();
    }, [pageNumber]);

    const fetchInitialData = async () => {
        var result = await getTickets(pageNumber, setAuthentication);
        if(result !== null) {
            if(result.status === 200) {
                setData(result.data)
                setLoading(false)
            }
        }
    }

    return (
        <Card className="ran-card">
            <Card.Header className="ran-card-header text-white">
                HELP DESK
                <Button variant="warning" className="text-dark" style={{float:"right"}}>
                <Link variant="warning" className="text-dark" style={{float:"right", textDecoration: "none"}} to={`/helpdesk/create`}>CREATE TICKET <MdCreateNewFolder/></Link>      
                </Button>   
            
                
            </Card.Header>
            <Card.Body>
                {
                    isLoading ? 
                    (
                        <Bubbles />
                    )
                    : 
                    (
                        <>
                            <Table striped bordered hover variant="dark" responsive>
                                <thead>
                                    <tr>
                                        <th>Ticket #</th>
                                        <th>Date Created</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                   { data.dataList.map((item, index) => 
                                     <tr key={index}>
                                        <td><Link to={`/helpdesk/${item.id}`} style={{textDecoration:"none"}}>{item.id}</Link></td>
                                        
                                        <td>{item.fomattedCreatedDate}</td>
                                        {
                                            item.status === 'PENDING' &&
                                            <td style={{color:"green"}}>{item.status}</td>
                                        }
                                        {
                                            item.status === 'DONE' &&
                                            <td style={{color:"lightblue"}}>{item.status}</td>
                                        }
                                        {
                                            item.status === 'WAITING' &&
                                            <td style={{color:"yellow"}}>{item.status}</td>
                                        }
                                        {
                                            item.status === 'DISAPPROVED' &&
                                            <td style={{color:"red"}}>{item.status}</td>
                                        }
                                    </tr>
                                   )}
                                </tbody>
                            </Table>
                            <PaginationButton data={data} setPageNumber={setPageNumber} />
                        </>
                    )
                }
            </Card.Body>
        </Card>
    )
}

export default Tickets
