import Card from 'react-bootstrap/Card'
import { useUrlSearchParams } from "use-url-search-params";
import { userMaintenance } from '../helpers/APIHelper'
import React from 'react';
import Button  from 'react-bootstrap/Button'
import { Formik } from 'formik'
import { Form, Alert, Row, Col  } from 'react-bootstrap'
import { InputGroup } from 'react-bootstrap'
import * as yup from "yup";
import ErrorModal from '../components/modals/ErrorModal'
import Bubbles from './loading/Bubbles';

const Register = ( {setAuthentication}) => {

    const [params, setParams] = useUrlSearchParams()

    const [modalShow, setModalShow] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false)
    const [isSuccess, setSuccess] = React.useState(false);

    const schema = yup.object().shape({
        username: yup.string().required("Username is required."),
        password: yup.string().required("Password is required."),
        confirmPassword: yup.string().required("Confirm password is required."),
        email: yup.string().required("Email Address is required."),
        pinCode: yup.string().required("Pin Code is required."),
        confirmPinCode: yup.string().required("Confirm Pin Code is required."),
        securityKey: yup.string().required("Security Key is required."),
        referralCode: yup.string()
    });


    return (
        <Card className="ran-card">
            <Card.Header className="ran-card-header text-white">REGISTER</Card.Header>
            <Card.Body>
                <>
                            {
                                isSuccess &&
                                <Alert variant="info" >
                                    Account has been successfully created.
                                </Alert>
                            }
                            <Formik
                                initialValues={{
                                    username: "",
                                    password:"",
                                    confirmPassword: "",
                                    email:"",
                                    pinCode:"",
                                    confirmPinCode: "",
                                    securityKey:"",
                                    referralCode: params.referralCode
                                }}
                                onSubmit={async (values, {resetForm}) => {
                                    setLoading(true)
                                    setSuccess(false)
                                    
                                    var result = await userMaintenance('REGISTER', values, setAuthentication);
                                    if(result !== null || result !== undefined){
                                        if(result.success) {
                                            setLoading(false)
                                            setSuccess(true)
                                            resetForm({
                                                username: "",
                                                password:"",
                                                confirmPassword: "",
                                                email:"",
                                                pinCode:"",
                                                confirmPinCode: "",
                                                securityKey:"",
                                                referralCode:""
                                            })
                                        }
                                        else{
                                            // not found or bad request.
                                            if(result.status === 404 || result.status === 400){
                                                setModalShow(true)
                                                setErrors(result.errors)
                                                setLoading(false)
                                            }
                                            // do nothing if unauthorize.
                                        }
                                    }
                                }}
                                validationSchema={schema}>
                                {({
                                errors,
                                touched,
                                handleBlur,
                                handleChange,
                                isSubmitting,
                                values,
                                handleSubmit
                                }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Form.Group md="4" controlId="validateFormUsername">
                                            <Form.Label>Username</Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control
                                                type="text"
                                                placeholder="Username"
                                                name="username"
                                                value={values.username}
                                                onChange={handleChange}
                                                isInvalid={!!errors.username}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                {errors.username}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Row>
                                            <Col>
                                                <Form.Group md="4" controlId="validateFormPassword" className="mt-2">
                                                    <Form.Label>Password</Form.Label>
                                                    <InputGroup hasValidation>
                                                        <Form.Control
                                                        type="password"
                                                        placeholder="Password"
                                                        name="password"
                                                        value={values.password}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.password}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                        {errors.password}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group md="4" controlId="validateFormConfirmPassword" className="mt-2">
                                                    <Form.Label>Confirm Password</Form.Label>
                                                    <InputGroup hasValidation>
                                                        <Form.Control
                                                        type="password"
                                                        placeholder="Confirm Password"
                                                        name="confirmPassword"
                                                        value={values.confirmPassword}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.confirmPassword}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                        {errors.confirmPassword}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Form.Group md="4" controlId="validateFormEmail" className="mt-2">
                                            <Form.Label>Email Address</Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control
                                                type="text"
                                                placeholder="Email Address"
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                isInvalid={!!errors.email}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                {errors.email}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Row>
                                            <Col>
                                                <Form.Group md="4" controlId="validateFormPin" className="mt-2">
                                                <Form.Label>PIN Code</Form.Label>
                                                <InputGroup hasValidation>
                                                    <Form.Control
                                                    type="password"
                                                    placeholder="PIN Code"
                                                    name="pinCode"
                                                    value={values.pinCode}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.pinCode}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                    {errors.pinCode}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group md="4" controlId="validateFormConfirmPin" className="mt-2">
                                                <Form.Label>Confirm PIN Code</Form.Label>
                                                <InputGroup hasValidation>
                                                    <Form.Control
                                                    type="password"
                                                    placeholder="Confirm PIN Code"
                                                    name="confirmPinCode"
                                                    value={values.confirmPinCode}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.confirmPinCode}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                    {errors.confirmPinCode}
                                                    </Form.Control.Feedback>
                                                    </InputGroup>
                                                    </Form.Group>
                                            </Col>
                                        </Row>
                                        <Form.Group md="4" controlId="validateFormKey" className="mt-2">
                                            <Form.Label>Security Key</Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control
                                                type="password"
                                                placeholder="Security Key"
                                                name="securityKey"
                                                value={values.securityKey}
                                                onChange={handleChange}
                                                isInvalid={!!errors.securityKey}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                {errors.securityKey}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group md="4" controlId="validateFormKey" className="mt-2">
                                            <Form.Label>Referral Code (Optional)</Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control
                                                type="referralCode"
                                                placeholder="Referral Code"
                                                name="referralCode"
                                                value={values.referralCode}
                                                onChange={handleChange}
                                                isInvalid={!!errors.referralCode}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                {errors.referralCode}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        {
                                            isLoading && 
                                            (
                                                <div className="mt-3">
                                                    <Bubbles />
                                                </div>
                                            )
                                        }
                                        <Form.Group  className="mt-4 mb-0">
                                            <Button  disabled={isLoading} type="submit" variant="success" className="btn-success text-white" style={{float:"right", width: "100%"}}>
                                                            {
                                                                isLoading ? 'PROCESSING' : 'REGISTER'
                                                            }
                                            </Button>
                                        </Form.Group>
                                </Form>
                                )}
                            </Formik>
                            <ErrorModal
                                errors={errors}
                                show={modalShow}
                                onHide={() => setModalShow(false)}/>
                        </>
            </Card.Body>
        </Card>
    )
}

export default Register
