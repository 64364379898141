import { Alert } from 'react-bootstrap'
import React from 'react'
import Card from 'react-bootstrap/Card'
import { MdCancel } from "react-icons/md";

const Failed = () => {
    return (
        <Card className="ran-card">
        <Card.Header className="ran-card-header text-white">TOP UP</Card.Header> 
        <Card.Body>
                <Alert variant="danger" >
                        <b><MdCancel />&nbsp; TOP UP FAILED!</b> <br/><br/>
                        Your transaction was not successful. Please try again later.
                </Alert>
        </Card.Body>
    </Card>
    )
}

export default Failed
