import Card from 'react-bootstrap/Card'
import { userMaintenance } from '../../helpers/APIHelper'
import React from 'react';
import Button  from 'react-bootstrap/Button'
import { Formik } from 'formik'
import { Form, Alert  } from 'react-bootstrap'
import { InputGroup } from 'react-bootstrap'
import * as yup from "yup";
import ErrorModal from '../../components/modals/ErrorModal'
import Bubbles from '../loading/Bubbles';

const ForgotPassword = ( {setAuthentication} ) => {
    
    const [modalShow, setModalShow] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false)
    const [isSuccess, setSuccess] = React.useState(false);

    const schema = yup.object().shape({
        email: yup.string().required("Email address is required."),
    });

    return (
        <Card className="ran-card">
            <Card.Header className="ran-card-header text-white">FORGOT PASSWORD</Card.Header>
            <Card.Body>
                <>
                            {
                                isSuccess &&
                                <Alert variant="info" >
                                    Password successfully sent to your registered email address.
                                </Alert>
                            }
                             <Formik
                                initialValues={{
                                    email: "",
                                }}
                                onSubmit={async (values, {resetForm}) => {
                                    setLoading(true)
                                    setSuccess(false)
                                    
                                    var result = await userMaintenance('FORGOT_PASSWORD', values, setAuthentication);
                                    if(result !== null || result !== undefined){
                                        if(result.success) {
                                            setLoading(false)
                                            setSuccess(true)
                                            resetForm({
                                                email: "",
                                            })
                                        }
                                        else{
                                            // not found or bad request.
                                            if(result.status === 404 || result.status === 400){
                                                setModalShow(true)
                                                setErrors(result.errors)
                                                setLoading(false)
                                            }
                                            // do nothing if unauthorize.
                                        }
                                    }
                                }}
                                validationSchema={schema}>
                                {({
                                errors,
                                touched,
                                handleBlur,
                                handleChange,
                                isSubmitting,
                                values,
                                handleSubmit
                                }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Form.Group md="4" controlId="validateFormEmail">
                                            <Form.Label>Email address</Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control
                                                type="text"
                                                placeholder="Email address"
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                isInvalid={!!errors.email}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                {errors.email}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        {
                                            isLoading && 
                                            (
                                                <div className="mt-3">
                                                   <Bubbles />
                                                </div>
                                            )
                                        }
                                        <Form.Group  className="mt-4 mb-0">
                                            <Button  disabled={isLoading} type="submit" variant="warning" className="btn-danger text-white" style={{width: "100%"}}>
                                                            {
                                                                isLoading ? 'PROCESSING' : 'SUBMIT'
                                                            }
                                            </Button>
                                        </Form.Group>
                                </Form>
                                )}
                            </Formik>
                            <ErrorModal
                                errors={errors}
                                show={modalShow}
                                onHide={() => setModalShow(false)}/>
                        </>
            </Card.Body>
        </Card>
    )
}

export default ForgotPassword
