import React from 'react'
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import { useState, useEffect } from 'react'
import { getDownloads } from '../helpers/APIHelper'
import Bubbles from './loading/Bubbles';

const Downloads = () => {

    const [isLoading, setLoading] = useState(true)

    const [data, setData] = useState({
        data : {
            downloads:[]
        }
    })

    useEffect(() => {
        async function fetchData() {
            await fetchInitialData();
        }

        fetchData();
    }, []);

    const fetchInitialData = async () => {
        var result = await getDownloads();
        if(result !== null) {
            setData({ data: result.data})
            setLoading(false)
        }
    }

    return (
        <Card className="ran-card">
            <Card.Header className="ran-card-header text-white">DOWNLOADS</Card.Header>
            <Card.Body>
                {
                    isLoading ? 
                    <Bubbles />
                    : 
                    (<ListGroup className="text-white" variant="flush">
                    { data.data.downloads.map((data, index) => 
                        <ListGroup.Item key={index} className="downloads-item">
                            <div className="text-white">
                                <h2>{data.title}</h2>
                                <span>Created Date: {data.displayCreatedDate}</span>
                                <br/>
                                <span>
                                    <div dangerouslySetInnerHTML={{
                                        __html: data.text
                                    }}>
                                    </div>
                                </span>
                            </div>
                        </ListGroup.Item>
                    )}
                </ListGroup>)
                }
            </Card.Body>
        </Card>
    )
}

export default Downloads
