import Card from 'react-bootstrap/Card'
import { userMaintenance } from '../../helpers/APIHelper'
import React from 'react';
import Button  from 'react-bootstrap/Button'
import { Alert  } from 'react-bootstrap'
import ErrorModal from '../../components/modals/ErrorModal'
import { FaInfoCircle } from "react-icons/fa";
import Bubbles from '../loading/Bubbles';

const ForgotPin = ({setAuthentication} ) => {

    const [modalShow, setModalShow] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false)
    const [isSuccess, setSuccess] = React.useState(false);

    const onSubmit = async () => {
        setLoading(true)
        setSuccess(false)  

        var result = await userMaintenance('FORGOT_PIN_CODE', {}, setAuthentication);         
        if(result !== null || result !== undefined){                            
                if(result.success) {      
                    setLoading(false)                              
                    setSuccess(true)                                
            }
        else{
                // not found or bad request.
                if(result.status === 404 || result.status === 400){
                    setModalShow(true)
                    setErrors(result.errors)
                    setLoading(false)
                }
                // do nothing if unauthorize.
            }
        }                                 
    }

    return (
        <Card className="ran-card">
            <Card.Header className="ran-card-header text-white">FORGOT PIN CODE</Card.Header>
            <Card.Body>
            <div className="p-2">
                    <>
                    {
                        isSuccess &&
                        <Alert variant="info" className="text-center">
                             Pin code has been sent to your registered email address.
                        </Alert>
                    }
                    <Alert variant="primary" className="text-center">
                        <FaInfoCircle/>&nbsp; Click the "SEND PIN CODE" button to receive your Pin Code in your registered Email Address.
                    </Alert>  
                    {
                        isLoading &&
                        <Bubbles />
                    }
                    <Button  disabled={isLoading} onClick={onSubmit} variant="warning" className="btn-danger text-white" style={{ width: "100%"}}>
                                {
                                    isLoading ? 'SENDING PIN CODE' : 'SEND PIN CODE'
                                }
                    </Button>         
                    <ErrorModal
                        errors={errors}
                        show={modalShow}
                        onHide={() => setModalShow(false)}/>
                    </>  
                </div>
            </Card.Body>
        </Card>
    )
}

export default ForgotPin
