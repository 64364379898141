import React from 'react'
import Card from 'react-bootstrap/Card'
import { useState, useEffect } from 'react'
import { getShopRecords } from '../../helpers/APIHelper'
import { Table,Button } from 'react-bootstrap'
import { IoIosArrowBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import PaginationButton from '../PaginationButton'
import Bubbles from '../loading/Bubbles';

const ShopRecords = ( {setAuthentication} ) => {

    let history = useHistory();

    const [isLoading, setLoading] = useState(true)
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({
        data : {}
    })

    useEffect(() => {
        async function fetchData() {
            await fetchInitialData();
        }
        fetchData();
    }, [pageNumber]);

    const fetchInitialData = async () => {
        var result = await getShopRecords(pageNumber, setAuthentication);
        if(result !== null) {
            if(result.status === 200) {
                setData(result.data)
                setLoading(false)
            }
        }
    }

    const getCurrency = (data) =>{
        if(data.premPoints !== data.afterPremPoints){
            return "E-POINTS";
        }else{
            return "GAME POINTS";
        }
    }

    return (
        <Card className="ran-card">
            <Card.Header className="ran-card-header text-white">
                SHOP RECORDS
            <Button onClick={() => history.goBack() }  variant="warning" className="text-dark" style={{float:"right"}}>
               <IoIosArrowBack /> BACK
            </Button>          
            </Card.Header>
            <Card.Body>
                {
                    isLoading ? 
                    (
                        <Bubbles />
                    )
                    : 
                    (
                        <>
                            <Table striped bordered hover variant="dark" responsive>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Item #</th>
                                        <th>Price</th>
                                        <th>Latest Points</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                   { data.dataList.map((item, index) => 
                                     <tr key={index}>
                                        <td>{item.itemName}</td>
                                        <td>{item.productNum}</td>
                                        <td>{item.itemPrice } {getCurrency(item)}</td>
                                        <td>( {item.afterPremPoints} ) E-POINTS | ( {item.afterVotePoints} ) GAME POINTS</td>
                                        <td>{item.displayDateBought }</td>
                                    </tr>
                                   )}
                                </tbody>
                            </Table>
                            <PaginationButton data={data} setPageNumber={setPageNumber} />
                        </>
                    )
                }
            </Card.Body>
        </Card>
    )
}

export default ShopRecords
