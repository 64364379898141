import { Alert } from 'react-bootstrap'
import React from 'react'
import Card from 'react-bootstrap/Card'
import { FaCheck } from "react-icons/fa";

const Success = () => {
    return (
        <Card className="ran-card">
        <Card.Header className="ran-card-header text-white">TOP UP</Card.Header> 
        <Card.Body>
                <Alert variant="info" >
                        <b><FaCheck />&nbsp; TOP UP SUCCESSFUL!</b> <br/><br/>
                        Premium Points will be reflected in your account as soon as possible.
                </Alert>
        </Card.Body>
    </Card>
    )
}

export default Success
