import schoolLogo0 from '../assets/images/schools_logo/0.png'
import schoolLogo1 from '../assets/images/schools_logo/1.png'
import schoolLogo2 from '../assets/images/schools_logo/2.png'

import schoolImage0 from '../assets/images/school/0.jpg'
import schoolImage1 from '../assets/images/school/1.jpg'
import schoolImage2 from '../assets/images/school/2.jpg'
import schoolImage3 from '../assets/images/school/3.jpg'

import class0 from '../assets/images/class/1.jpg'
import class2 from '../assets/images/class/2.jpg'
import class4 from '../assets/images/class/4.jpg'
import class8 from '../assets/images/class/8.jpg'
import class16 from '../assets/images/class/16.jpg'
import class32 from '../assets/images/class/32.jpg'
import class64 from '../assets/images/class/64.jpg'
import class128 from '../assets/images/class/128.jpg'
import class256 from '../assets/images/class/256.jpg'
import class512 from '../assets/images/class/512.jpg'
import class1024 from '../assets/images/class/1024.jpg'
import class2048 from '../assets/images/class/2048.jpg'
import class4096 from '../assets/images/class/4096.jpg'
import class8192 from '../assets/images/class/8192.jpg'

export function getSchoolImage(id) {
    switch(id){
        case 0:
            return schoolImage0;
        case 1:
            return schoolImage1;
        case 2:
            return schoolImage2;
        case 3 :
            return schoolImage3;
        default:
            return schoolImage0;
    }
}

export function getSchoolSmallLogo(id) {
    switch(id) {
        case 0:
            return schoolLogo0;
        case 1:
            return schoolLogo1;
        case 2:
            return schoolLogo2;
        default:
            return schoolLogo0;   
    }
}

export function getClassImage(id) {
    switch(id) {
        case 0:
            return class0;
        case 2:
            return class2;
        case 4:
            return class4;
        case 8 :
            return class8;
        case 16:
            return class16;
        case 32 :
            return class32;
        case 64:
             return class64;
        case 128 :
            return class128;
        case 256:
             return class256;
        case 512 :
             return class512;  
        case 1024:
             return class1024;
        case 2048 :
            return class2048; 
        case 4096:
            return class4096;
        case 8192 :
            return class8192;  
        default:
            return class0;         
    }
}
