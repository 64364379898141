import Card from 'react-bootstrap/Card'
import { userMaintenance, getUserGameTime } from '../../helpers/APIHelper'
import React from 'react';
import Button  from 'react-bootstrap/Button'
import { Alert  } from 'react-bootstrap'
import ErrorModal from '../../components/modals/ErrorModal'
import $ from 'jquery'
import Bubbles from '../loading/Bubbles';

const GameTimeToGamePoints = ( {setAuthentication} ) => {
    
    const [modalShow, setModalShow] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const [isLoading, setLoading] = React.useState(true)
    const [isSuccess, setSuccess] = React.useState(false);

    const [data, setData] = React.useState({
        collectedHours:0,
        collectedMinutes:0,
        gamePointsToGet:0,
        requiredGameTimeHours: 1,
        gameTimeMinuteToPoint: 1,
        conversionLevelRequirement: 60
     })

     React.useEffect(() => {
        async function fetchData() {
             await fetchInitialData();
        }
        fetchData();
    }, []);

    const fetchInitialData = async () => {
        var result = await getUserGameTime(setAuthentication);
        if(result !== null) {
            if(result.status === 200) {
                setData(result.data)
                setLoading(false)
            }
        }
    }

    const onConvert = async () => {
        setLoading(true)
        setSuccess(false)  

        var result = await userMaintenance('GAMETIME_GAMEPOINTS', {}, setAuthentication);         
        if(result !== null || result !== undefined){                            
                if(result.success) {      
                    setData(result.data)
                     $('#gamePoints').html(result.data.gamePoints) 
                    setLoading(false)                              
                    setSuccess(true)                                
            }
        else{
                // not found or bad request.
                if(result.status === 404 || result.status === 400){
                    setModalShow(true)
                    setErrors(result.errors)
                    setLoading(false)
                }
                // do nothing if unauthorize.
            }
        }                                 
    }

    return (
        <Card className="ran-card">
            <Card.Header className="ran-card-header text-white">GAMETIME TO GAMEPOINTS</Card.Header>
            <Card.Body>
                {
                    isLoading ? 
                    (
                        <div className="mt-3">
                            <Bubbles />                                                                                
                        </div>
                    )
                    :
                    (
                    <div className="p-2">
                    <>
                    {
                        isSuccess &&
                        <Alert variant="info" className="text-center">
                            GameTime has been successfully converted.
                        </Alert>
                    }
                    <Alert variant="primary" className="text-center">
                        Collected GameTime: <b>{data.collectedHours}</b> Hour(s) &  <b>{data.collectedMinutes}</b> Minute(s)
                        <br/>
                        You will need a Level <b>{data.conversionLevelRequirement}</b> character before you start farming game time points.
                    </Alert>   
                    <Alert variant="warning" className="text-center">
                        At this time you will get ( <b>{data.gamePointsToGet}</b> ) Game Points
                        <br/>
                        <b>{data.requiredGameTimeHours}</b> Hour(s) = <b>{data.gameTimeMinuteToPoint}</b> Game Point(s)
                    </Alert>
                    <Button  disabled={isLoading} onClick={onConvert} variant="warning" className="btn-danger text-white" style={{ width: "100%"}}>
                                {
                                    isLoading ? 'PROCESSING' : 'CONVERT GAMETIME TO GAMEPOINTS'
                                }
                    </Button>         
                    <ErrorModal
                        errors={errors}
                        show={modalShow}
                        onHide={() => setModalShow(false)}/>
                    </>  
                </div>
                    )
                }
            </Card.Body>
        </Card>
    )
}

export default GameTimeToGamePoints
