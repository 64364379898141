import React from 'react'
import Card from 'react-bootstrap/Card'
import { useState, useEffect } from 'react'
import { Row } from 'react-bootstrap'
import { FaShoppingCart } from "react-icons/fa";
import { getPremiumPoints } from '../helpers/APIHelper'
import { Link } from 'react-router-dom';
import Bubbles from './loading/Bubbles';

const TopUp = () => {

    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState({
        data : []
    })

    useEffect(() => {
        async function fetchData() {
            await fetchInitialData();
        }

        fetchData();
    }, []);

    const fetchInitialData = async () => {
        var result = await getPremiumPoints();
        if(result !== null) {
            setData({ data: result.data})
            setLoading(false)
        }
    }

    return (
        <Card className="ran-card">
            <Card.Header className="ran-card-header text-white">TOP UP</Card.Header>
            <Card.Body>
                {
                    isLoading === true ? 
                   (
                       <Bubbles />
                   )
                    :
                    (<Row style={{ padding: "20px" }}>
                    {/* { data.data.map((premiumPoint, index) =>
                        <Card key={index} className="top-up-card">
                        <Card.Img variant="top" src={premiumPoint.imageLink} />
                        <Card.Body className="text-center">
                            <Card.Title>{premiumPoint.name}</Card.Title>
                            <Link className="btn btn-primary btn-lg" to={`/topup/checkout/${premiumPoint.id}`}><FaShoppingCart /> Buy Now</Link> 
                        </Card.Body>
                        </Card>
                    )} */}
                    </Row>)
                }
            </Card.Body>
        </Card>
    )
}

export default TopUp
