import React from 'react'
import Card from 'react-bootstrap/Card'
import { useState } from 'react'

const ServerInfo = () => {

    const [data] = useState({
        data : '<div class="panel-body"><p></p><h4>Available Servers:</h4>🔲 Strife Server <br>🔲 Havoc Server 🔜 (To be added) <br>🔲 Fury Server🔜 (To be added) <br><h4>Classic Gameplay!</h4>🔲4 Class (Brawler, Swordsman, Archer and Shaman) <br>🔲155 Max Level <br>🔲147 Max Skill <br>🔲High Exp Rate <br>🔲Low Drop Rate <br>🔲Party Leveling <br>🔲Farm Based <br>🔲Quest Based <br>🔲Cheat Protected (LR/AOE/ZOOM/HPF/AS) <br>🔲Official Ran Online Launcher with old Login Function. (Developed by KHLEA Security)<p></p></div>'
    })
{/* <h4>Server Owner: Peter Kent Shaw</h4>Address: Melbourne, Australia, VIC <br>Contact Number: 0422354692 <br><br></br> */}
    return (
        <Card className="ran-card">
            <Card.Header className="ran-card-header text-white">SERVER INFO</Card.Header>
            <Card.Body>
            <span>
                <div dangerouslySetInnerHTML={{
                     __html: data.data
                     }}>
                </div>
             </span>
            </Card.Body>
        </Card>
    )
}

export default ServerInfo
