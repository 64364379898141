import React from 'react'
import Card from 'react-bootstrap/Card'

const NotFound = () => {
    return (
        <Card className="ran-card">
        <Card.Header className="ran-card-header text-white">PAGE NOT FOUND</Card.Header> 
        <Card.Body className="text-white">
            <span>
                 <h3>This page could not be found.</h3> 
            </span>
        </Card.Body>
    </Card>
    )
}

export default NotFound
