import React from 'react'
import Card from 'react-bootstrap/Card'
import { useState, useEffect } from 'react'
import { getTicketById, replyTicket } from '../../../helpers/APIHelper'
// import { serverTicketImageStorage } from '../../../helpers/APIConfig'
import { Formik, Field } from 'formik'
import { Button, Form } from 'react-bootstrap'
import * as yup from "yup";
import Bubbles from '../../loading/Bubbles';
import { useHistory } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { Redirect } from 'react-router'
import ErrorModal from '../../modals/ErrorModal';

const TicketItem = ( props, {setAuthentication} ) => {

    let history = useHistory();
    let selectedId = props.match.params.id
    
    const [isLoading, setLoading] = useState(true)
    const [modalShow, setModalShow] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const [notFound, setNotFound] = useState(true)
    const [isSuccess, setSuccess] = React.useState(false);
    const [data, setData] = useState({
        data : {}
    })

    // const [message, setMessage] = useState('')

    const schema = yup.object().shape({
         message: yup.string().required("Message is required."),
    });

    useEffect(() => {
        async function fetchData() {
            await fetchInitialData();
        }
        fetchData();
    }, []);

    const fetchInitialData = async () => {

        if(selectedId === undefined || selectedId === null || isNaN(selectedId)){
            setLoading(false)
            setNotFound(true)
            return;
        }

        var result = await getTicketById({ id: selectedId }, setAuthentication);
        if(result !== null) {
            if(result.status === 200) {
                setNotFound(false)
                setData(result.data)
                setLoading(false)
            }else  {
                if(result.status === 404) {
                    setNotFound(true)
                }
            }
        }
        else{
            setNotFound(true)
        }

        setLoading(false)
    }

    return (
        <Card className="ran-card">
            <Card.Header className="ran-card-header text-white">
                TICKET DETAILS
                <Button onClick={() => history.goBack() }  variant="warning" className="text-dark" style={{float:"right"}}>
                <IoIosArrowBack /> BACK
                </Button>  
            </Card.Header>
            <Card.Body>
                {
                    isLoading ? 
                    (
                        <Bubbles />
                    )
                    : 
                    (
                        <>
                            {
                                notFound === true ? <Redirect to="/not-found" /> 
                                :
                                (<div className="text-white">
                                    <Card>
                                        <Card.Header className="ran-card-header text-white">
                                            {data.formattedTitle}
                                        </Card.Header>
                                        <Card.Body>
                                            Status: &nbsp;
                                            {
                                                data.formattedStatus === 'PENDING' &&
                                                <span style={{color:"green"}}>{data.formattedStatus}</span>
                                            }
                                            {
                                                data.formattedStatus === 'DONE' &&
                                                <span style={{color:"lightblue"}}>{data.formattedStatus}</span>
                                            }
                                            {
                                                data.formattedStatus === 'WAITING' &&
                                                <span style={{color:"yellow"}}>{data.formattedStatus}</span>
                                            }
                                            {
                                                data.formattedStatus === 'DISAPPROVED' &&
                                                <span style={{color:"red"}}>{data.formattedStatus}</span>
                                            }
                                            <br/>
                                            Amount: {data.amount} <br/>
                                            Message: {data.message} <br/>
                                            Proof of Transaction: 
                                            {
                                                data.image === null && <span> N/A </span>
                                            }
                                            {
                                                data.image !== null && ( <> <br/> <img src={data.image} alt="PoT" className="img-fluid"/> </>)
                                            }
                                             <br/> <br/>
                                            {/* Replies */}
                                            <h6>
                                                Replies
                                            </h6>
                                            {
                                                data.replyToDonations.length === 0 && (<center>
                                                    <i>There are currently no replies yet on your ticket.</i>
                                                </center>) 
                                            }
                                            {
                                                data.replyToDonations.length > 0 && 
                                                (<>
                                                    {
                                                        data.replyToDonations.map((dataItem, index) => 
                                                            <div key={index} className='card' style={{ textAlign: `${dataItem.sentBy === '@Administrator' ? "left" : "right"}` }}>
                                                            <div className='card-body' style={{padding: "10px", borderRadius: "10px"}}>
                                                                <h5 className="card-title">
                                                                    {
                                                                        dataItem.sentBy === '@Administrator' &&
                                                                        <span>Administrator replied {dataItem.formattedCreatedDate} </span>
                                                                    }
                                                                    {
                                                                        dataItem.sentBy !== '@Administrator' &&
                                                                        <span>{data.userName} replied {dataItem.formattedCreatedDate} </span>
                                                                    }
                                                                </h5>
                                                                <hr />
                                                                <div dangerouslySetInnerHTML={{
                                                                        __html: dataItem.message
                                                                        }}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        )
                                                    }
                                                </>) 
                                            }
                                            
                                            {/* Message function */}
                                            {
                                                data.formattedStatus !== 'DONE' && data.formattedStatus !== 'DISAPPROVED' && 
                                                (
                                                    <>
                                                        <br/>
                                                        <Formik
                                                            initialValues={{
                                                                message: ''
                                                            }}
                                                            onSubmit={async (values, {resetForm}) => {
                                                                setLoading(true)
                                                                setSuccess(false)
                                                                
                                                                var result = await replyTicket({ ticketId: Number(selectedId), message: values.message}, setAuthentication);
                                                                if(result !== null || result !== undefined){
                                                                    if(result.success) {
                                                                        setLoading(false)
                                                                        setSuccess(true)
                                                                        resetForm({
                                                                            message: ''
                                                                        })

                                                                        await fetchInitialData();
                                                                    }
                                                                    else{
                                                                        // not found or bad request.
                                                                        if(result.status === 404 || result.status === 400){
                                                                            setModalShow(true)
                                                                            setErrors(result.errors)
                                                                            setLoading(false)
                                                                        }
                                                                        // do nothing if unauthorize.
                                                                    }
                                                                }
                                                            }}
                                                            validationSchema={schema}>
                                                            {({
                                                            errors,
                                                            touched,
                                                            handleBlur,
                                                            handleChange,
                                                            isSubmitting,
                                                            values,
                                                            handleSubmit,
                                                            setFieldValue 
                                                            }) => (
                                                                <Form noValidate onSubmit={handleSubmit}>
                                                                    <Form.Group md="4" controlId="validateFormMessage">
                                                                        <Form.Label>Reply</Form.Label>
                                                                        <Field 
                                                                            className="form-control" 
                                                                            name="message" 
                                                                            placeholder="Message" 
                                                                            as="textarea" 
                                                                            value={values.message} onChange={handleChange}
                                                                             />

                                                                        <div className="error" style={{color: "#dc3545"}}>{errors.message}</div>
                                                                    </Form.Group>
                                                                    {
                                                                        isLoading && 
                                                                        (
                                                                            <div className="mt-3">
                                                                            <Bubbles />
                                                                            </div>
                                                                        )
                                                                    }
                                                                    <Form.Group  className="mt-4 mb-0">
                                                                        <Button  disabled={isLoading} type="submit" variant="warning" className="btn-danger text-white">
                                                                                        {
                                                                                            isLoading ? 'PROCESSING' : 'SUBMIT REPLY'
                                                                                        }
                                                                        </Button>
                                                                    </Form.Group>
                                                            </Form>
                                                            )}
                                                        </Formik>
                                                        <ErrorModal
                                                            errors={errors}
                                                            show={modalShow}
                                                            onHide={() => setModalShow(false)}/>
                                                    </>
                                                )
                                            }
                                        </Card.Body>
                                    </Card>
                                </div>)
                            }
                        </>
                    )
                }
            </Card.Body>
        </Card>
    )
}

export default TicketItem
