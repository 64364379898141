import React from 'react'
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import { useState, useEffect } from 'react'
import { getSchoolSmallLogo, getSchoolImage } from '../helpers/RANHelper.js'
import { getElectronicRoomsInfo } from '../helpers/APIHelper'
import Bubbles from './loading/Bubbles';

const ClubWars = () => {

    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState({
        data :{
            rooms:[]
        }
    });

    useEffect(() => {
        async function fetchData() {
            await fetchInitialData();
        }

        fetchData();
    }, []);

    const fetchInitialData = async () => {
        var result = await getElectronicRoomsInfo();
        if(result !== null) {
            setData({ data: result.data})
        }
        setLoading(false)
    }
    
    return (
        <Card className="ran-card">
            <Card.Header className="ran-card-header text-white">CLUB WARS</Card.Header>
            <Card.Body>
                {
                    isLoading ? 
                    <Bubbles />
                    : 
                    (<ListGroup className="text-white" variant="flush">
                    { data.data.rooms.map((data, index) => 
                        <ListGroup.Item key={index} className="individual-rankings-item">
                            <div className="d-flex">
                                <img src={getSchoolImage(data.regionId)} className="rounded" alt={data.class} />
                            </div>
                            <div className="text-white">
                                <div className="name"><img src={getSchoolSmallLogo(data.guildSchool)} className="rounded" alt={data.school} />&nbsp; {data.guildName}</div>
                                E-Room: <span style={{ fontWeight: "bold"}}>{data.school}</span>
                                <br />
                                Master: <span style={{ fontWeight: "bold"}}>{data.clubMaster}</span>
                                <br />
                                Members: <span style={{ fontWeight: "bold"}}>{data.guildMembersCount}</span>
                                <br />
                                Tax: <span style={{ fontWeight: "bold"}}>{data.tax}</span>
                            </div>
                        </ListGroup.Item>
                    )}
                </ListGroup>)
                }
            </Card.Body>
        </Card>
    )
}

export default ClubWars
