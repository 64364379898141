import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { MdErrorOutline } from "react-icons/md";

const ErrorModal = (props) => {
    return (
        <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton style={{ backgroundColor: "#dc3545"}}>
            <Modal.Title id="contained-modal-title-vcenter" className="text-white">
                <MdErrorOutline /> Error
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
            { props.errors.map((error, index) => 
                <h6 key={index} className="text-dark">
                      <div dangerouslySetInnerHTML={{
                     __html: error
                     }}>
                    </div>
                    </h6>
            )}
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
        </Modal>
    )
}

export default ErrorModal
