import React from 'react'
import Card from 'react-bootstrap/Card'
import { useState, useEffect } from 'react'
import { getNewsById } from '../helpers/APIHelper'
import { Redirect } from 'react-router'
import { Button } from 'react-bootstrap';
import { IoIosArrowBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import Bubbles from './loading/Bubbles';

const NewsItem = ( props ) => {

    let history = useHistory();
    const selectedId = props.match.params.id

    const [isLoading, setLoading] = useState(true)
    const [notFound, setNotFound] = useState(true)
    const [data, setData] = useState({
        data:{ }
    });

    useEffect(() => {
        async function fetchData() {
            await fetchInitialData();
        }

        fetchData();
    }, []);

    const fetchInitialData = async () => {

        if(selectedId === undefined || selectedId === null || isNaN(selectedId)){
            setLoading(false)
            setNotFound(true)
            return;
        }

        var result = await getNewsById({ id: selectedId });
        
        if(result !== null) {
            if(result.success === true) {
                setNotFound(false)
                setData(result.data)
            }
            else  {
                if(result.status === 404) {
                    setNotFound(true)
                }
            }
        }
        else{
            setNotFound(true)
        }

        setLoading(false)
    }

    return (
        <>
        {
            isLoading ? 
            (
                <>
                    <Card className="ran-card">
                    <Card.Header className="ran-card-header text-white">NEWS</Card.Header> 
                        <Card.Body>
                            <Bubbles />
                        </Card.Body>
                    </Card>
                    
                </>
            )
            : 
            (
                <>
                    <Card className="ran-card">
                    <Card.Header className="ran-card-header text-white">{data.title}
                    <Button onClick={() => history.goBack() }  variant="warning" className="text-dark" style={{float:"right"}}>
                        <IoIosArrowBack /> BACK
                    </Button>     
                    </Card.Header> 
                        <Card.Body>
                            {
                                notFound === true ? <Redirect to="/not-found" /> 
                                :
                                (<div className="text-white">
                                    <span>Created Date: {data.displayCreatedDate}</span>
                                    <br/>
                                    <br/>
                                    <span>
                                            <div dangerouslySetInnerHTML={{
                                                __html: data.text
                                            }}>
                                            </div>
                                    </span>
                                </div>)
                            }
                        </Card.Body>
                    </Card>
                </>
            )
        }
        </>
    )
}


export default NewsItem
