import React from 'react'
import Card from 'react-bootstrap/Card'
import { useState, useEffect } from 'react'
import { Badge } from 'react-bootstrap'
import { FaNewspaper } from "react-icons/fa";
import newImage from '../assets/images/new.png'
import { getNews } from '../helpers/APIHelper'
import { Link } from 'react-router-dom';
import Bubbles from './loading/Bubbles';

const News = () => {

    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState({
        data:{
            newsList : []
        }
    });

    useEffect(() => {
        async function fetchData() {
            await fetchInitialData();
        }

        fetchData();
    }, []);

    const fetchInitialData = async () => {
        var result = await getNews();
        if(result !== null) {
            setData({ data: result.data})
            setLoading(false)
        }
    }


    return (
        <>
            {
                isLoading ? 
                (
                    <Card className="ran-card">
                        <Card.Header className="ran-card-header text-white">NEWS</Card.Header>
                        <Card.Body className="news-content-body">
                            <Bubbles />
                        </Card.Body>
                    </Card>
                )
                : 
                (<Card className="ran-card">
                        { data.data.newsList.map((data, index1) =>
                            <div key={index1}>
                                <Card.Header className="ran-card-header text-white">{data.title}</Card.Header>
                                <Card.Body className="news-content-body">
                                        { data.newsDisplayItem.map((newsItem, index2) =>
                                            <div className="news-data-item text-white" key={index2}>
                                                <FaNewspaper /> &nbsp;
                                                <Link to={`/news/${newsItem.id}`}>{newsItem.displayTitle}</Link>
                                                {
                                                    newsItem.isNew && 
                                                    <>
                                                        &nbsp;
                                                        <img src={newImage} alt="Is New" />
                                                    </>
                                                }
                                                <Badge bg="warning" text="dark">
                                                    {newsItem.dateCreated}
                                                </Badge>
                                            </div>
                                        )}
                                </Card.Body>
                            </div>
                        )}
                </Card>)
            }
        </>
    )
}

export default News
