import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Home';
import Downloads from './components/Downloads';
import ServerInfo from './components/ServerInfo';

import './custom.css'
import Rankings from './components/Rankings';
import TopUp from './components/TopUp';
import NewsItem from './components/NewsItem';
import NotFound from './components/NotFound';
import Account from './components/user/Account';
import ChangePassword from './components/user/ChangePassword';
import ChangePin from './components/user/ChangePin';
import ChangeEmail from './components/user/ChangeEmail';
import GameTimeToGamePoints from './components/user/GameTimeToGamePoints';
import CheckOut from './components/topup/CheckOut';
import TopupRecords from './components/topup/TopupRecords';
import ForgotPin from './components/user/ForgotPin';
import ForgotPassword from './components/user/ForgotPassword';
import ResetPassword from './components/user/ResetPassword';
import Register from './components/Register';
import News from './components/News';
import Success from './components/topup/Success';
import Failed from './components/topup/Failed';
import ShopRecords from './components/shop/ShopRecords'
import { isLogin } from './helpers/StorageHelper';
import PilotMode from './components/user/PilotMode';
import Shop from './components/Shop';
import Tickets from './components/user/HelpDesk/Tickets';
import CreateTicket from './components/user/HelpDesk/CreateTicket';
import TicketItem from './components/user/HelpDesk/TicketItem';
import Whitelist from './components/user/Whitelist';

const App = () => {

  const [isAuthenticated, setAuthentication] = React.useState(isLogin())

  return (
    <Layout isAuthenticated={isAuthenticated} setAuthentication={setAuthentication}>
      <Switch>
      <Route exact path='/' component={Home} />
      <Route exact path='/news' component={News} />
      <Route exact path='/downloads' component={Downloads} />
      <Route exact path='/server-info' component={ServerInfo} />
      <Route exact path='/rankings' component={Rankings} /> 
      <Route exact path='/topup' component={TopUp} /> 
      <Route path='/register' component={() => <Register  setAuthentication={setAuthentication} />} /> 
      <Route exact path='/not-found' component={NotFound} />
      <Route exact path='/topup/success' component={Success} /> 
      <Route exact path='/topup/failed' component={Failed} /> 
      {/* <Route exact path='/shop' component={() => <Shop setAuthentication={setAuthentication} />} />  */}
      <Route exact path='/helpdesk' component={() => <Tickets setAuthentication={setAuthentication} />} /> 
      <Route exact path='/helpdesk/create' component={() => <CreateTicket setAuthentication={setAuthentication} />} /> 
      

      <Route exact path='/account' component={() => <Account setAuthentication={setAuthentication} />}  />
      <Route exact path='/change-password' component={() => <ChangePassword setAuthentication={setAuthentication} />}  />
      <Route exact path='/change-pin' component={() => <ChangePin setAuthentication={setAuthentication} />}  />
      <Route exact path='/forgot-pin' component={() => <ForgotPin setAuthentication={setAuthentication} />}  />
      <Route exact path='/change-email' component={() => <ChangeEmail setAuthentication={setAuthentication} />}  />
      <Route exact path='/gametime-gamepoints' component={() => <GameTimeToGamePoints setAuthentication={setAuthentication} />}  />
      <Route exact path='/pilot-mode' component={() => <PilotMode setAuthentication={setAuthentication} />}  />
      <Route exact path='/ip-whitelists' component={() => <Whitelist setAuthentication={setAuthentication} />}  />
      <Route exact path='/topup/records' component={() => <TopupRecords setAuthentication={setAuthentication} />}  />
      <Route exact path='/shop/records' component={() => <ShopRecords setAuthentication={setAuthentication} />}  />
      <Route exact path='/forgot-password' component={() => <ForgotPassword setAuthentication={setAuthentication} />}  />
      <Route path='/reset-password' component={() => <ResetPassword setAuthentication={setAuthentication} />}  />
      

      
      <Route exact path='/news/:id' component={NewsItem} />
      <Route exact path='/topup/checkout/:id' component={CheckOut} />
      <Route exact path='/helpdesk/:id' component={(props) => <TicketItem setAuthentication={setAuthentication} {...props} />} /> 

      <Route component = { NotFound } />
      </Switch>
    </Layout>
  )
}

export default App
