import React from 'react'
import Card from 'react-bootstrap/Card'
import { useState, useEffect } from 'react'
import { topUpCheckOut, requestTopup } from '../../helpers/APIHelper'
import { Redirect } from 'react-router'
import { Alert } from 'react-bootstrap';
import { FaInfoCircle, FaMoneyBillAlt } from "react-icons/fa";
import ErrorModal from '../modals/ErrorModal'
import { Formik } from 'formik'
import { Form, Button } from 'react-bootstrap'
import { InputGroup } from 'react-bootstrap'
import * as yup from "yup";
import Bubbles from '../loading/Bubbles';

const CheckOut = (props) => {

    const selectedId = props.match.params.id

    const [modalShow, setModalShow] = React.useState(false);
    const [isLoading, setLoading] = useState(true)
    const [isProcessing, setProcessing] = useState(false)
    const [notFound, setNotFound] = useState(true)
    const [errors, setErrors] = React.useState([]);
    const [data, setData] = useState({
        data:{ }
    });

    const schema = yup.object().shape({
        username: yup.string().required('Username is required.'),
        email: yup.string().required('Email is required.'),
    });

    useEffect(() => {
        async function fetchData() {
            await fetchInitialData();
        }

        fetchData();
    }, []);

    const fetchInitialData = async () => {

        if(selectedId === undefined || selectedId === null || isNaN(selectedId)){
            setLoading(false)
            setNotFound(true)
            return;
        }

        var result = await topUpCheckOut({ id: selectedId });
        if(result !== null) {
            if(result.success === true) {
                setNotFound(false)
                setData(result.data)
            }
            else  {
                if(result.status === 404) {
                    setNotFound(true)
                }
            }
        }
        else{
            setNotFound(true)
        }

        setLoading(false)
    }

    return (
        <Card className="ran-card">
        <Card.Header className="ran-card-header text-white">CHECK-OUT</Card.Header> 
        <Card.Body>
            {
                isLoading ? 
                <Bubbles />
                : 
                (
                    <>
                        {
                            notFound ? <Redirect to="/not-found" /> 
                            :
                            (<div className="col-md-8 text-white p-3">
                                <Alert variant="info">
                                    <span className="text-dark">
                                        <FaInfoCircle/>&nbsp;<b>Refund Policy</b> <br />
                                        (No Refund) All products are sold “as is”. You assume the responsibility for your purchase, and no refunds will be issued.
                                    </span>
                                </Alert>
                                <div className="text-white">
                                    <h4>Item Selected: <b>{data.premiumPoints.name}</b></h4>
                                    <div className="mt-3">
                                        <img src={data.premiumPoints.imageLink} width="400" alt="ep_photo" /> 
                                    </div>
                                    <div className="mt-3">
                                        <h4>Amount to Pay: <b>PHP {data.premiumPoints.price}</b></h4>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <Alert variant="primary">
                                        <h5 className="text-dark"><FaMoneyBillAlt />&nbsp;&nbsp;<b>Payment Details</b></h5>
                                    </Alert>
                                    <>
                                    <Formik
                                        initialValues={{
                                            username: data.autoFillUsername,
                                            email: data.autoFillEmail
                                        }}
                                        onSubmit={async (values) => {
                                            setProcessing(true)

                                            var result = await requestTopup({ 
                                                id : selectedId,
                                                username: values.username,
                                                email: values.email
                                            });

                                            if(result !== null || result !== undefined){
                                                if(result.success === true) {
                                                    setProcessing(false)
                                                    window.location.href = result.data.xendit_checkout_link;
                                                }
                                                else{
                                                    // not found or bad request.
                                                    if(result.status === 404 || result.status === 400){
                                                        setModalShow(true)
                                                        setErrors(result.errors)
                                                        setProcessing(false)
                                                    }
                                                    // do nothing if unauthorize.
                                                }
                                            }
                                        }}
                                        validationSchema={schema}>
                                        {({
                                        errors,
                                        touched,
                                        handleBlur,
                                        handleChange,
                                        isSubmitting,
                                        values,
                                        handleSubmit
                                        }) => (
                                            <Form noValidate onSubmit={handleSubmit}>
                                                    <Form.Group md="4" controlId="validateFormUsername">
                                                        <Form.Label>Username</Form.Label>
                                                        <InputGroup hasValidation>
                                                            <Form.Control
                                                            type="text"
                                                            placeholder="Username"
                                                            name="username"
                                                            value={values.username}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.username}
                                                            disabled={isProcessing}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                            {errors.username}
                                                            </Form.Control.Feedback>
                                                        </InputGroup>
                                                    </Form.Group>
                                                    <Form.Group md="4" controlId="validateFormEmail" className="mt-3">
                                                        <Form.Label>Email</Form.Label>
                                                        <InputGroup hasValidation>
                                                            <Form.Control
                                                            type="text"
                                                            placeholder="Email"
                                                            name="email"
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.email}
                                                            disabled={isProcessing}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                            {errors.email}
                                                            </Form.Control.Feedback>
                                                        </InputGroup>
                                                    </Form.Group>
                                                    {
                                                        isProcessing && 
                                                        (
                                                            <div className="mt-3">
                                                               <Bubbles />
                                                            </div>
                                                        )
                                                    }
                                                    <Form.Group  className="mt-4 mb-0">
                                                        <Button disabled={isProcessing} variant="danger" type="submit" style={{width:"100%"}}>
                                                            {
                                                                isProcessing ? 'PROCESSING' : 'PAY NOW'
                                                            }
                                                        </Button>
                                                    </Form.Group>
                                                </Form>
                                        )}
                                    </Formik>
                                    <ErrorModal
                                        errors={errors}
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                    />
                                    </>
                                </div>
                            </div>)
                        }
                    </>
                )
            }
        </Card.Body>
    </Card>
    )
}

export default CheckOut
