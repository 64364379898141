import React from 'react'
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import { useState, useEffect } from 'react'
import { getSchoolSmallLogo, getClassImage } from '../helpers/RANHelper.js'
import { getIndividualRankings } from '../helpers/APIHelper'
import Bubbles from './loading/Bubbles';

const IndividualRank = () => {

    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState({
        data : {
            topChars :[]
        }
    })

    useEffect(() => {
        async function fetchData() {
             await fetchInitialData();
        }

        fetchData();
    }, []);

    const fetchInitialData = async () => {
        var result = await getIndividualRankings();
        if(result !== null) {
            setData({ data: result.data})
            setLoading(false)
        }
        
    }

    return (
        <Card className="ran-card">
            <Card.Header className="ran-card-header text-white">TOP NOTCHERS</Card.Header>
            <Card.Body>
             {
                 isLoading ? 
                 (
                    <Bubbles />
                 )
                 : 
                 ((<ListGroup className="text-white" variant="flush">
                 { data.data.topChars.map((data, index) => 
                     <ListGroup.Item key={index} className="individual-rankings-item">
                         <div className="d-flex">
                             <img src={getClassImage(data.chaClass)} className="rounded" alt={data.class} />
                         </div>
                         <div className="text-white">
                             <div className="name"><img src={getSchoolSmallLogo(data.chaSchool)} alt={data.schoolName} />&nbsp; {data.chaName}</div>
                             Level: <span style={{ fontWeight: "bold"}}>{data.chaLevel}</span>
                             <br />
                             PK Kills: <span style={{color: "green", fontWeight: "bold"}}>{data.chaPkWin}</span>
                             <br />
                             PK Death: <span style={{color: "red", fontWeight: "bold"}}>{data.chaPkLoss}</span>
                         </div>
                     </ListGroup.Item>
                 )}
             </ListGroup>))

             }
            </Card.Body>
        </Card>
    )
}

export default IndividualRank
