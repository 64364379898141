import React from 'react'
import Card from 'react-bootstrap/Card'
import Button  from 'react-bootstrap/Button'
import { Formik } from 'formik'
import { Form } from 'react-bootstrap'
import { InputGroup } from 'react-bootstrap'
import * as yup from "yup";
import { authenticateUser } from '../helpers/Authenticate'
import ErrorModal from './modals/ErrorModal'
import { FaSignInAlt } from "react-icons/fa";
import { Link } from 'react-router-dom'

const Login = ({setAuthentication}) => {

    const [modalShow, setModalShow] = React.useState(false);
    const [loginErrors, setLoginErrors] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);

    const schema = yup.object().shape({
        username: yup.string().required('Username is required.'),
        password: yup.string().required('Password is required.'),
    });
    
    return (
        <>
            <Formik
            initialValues={{
            username: "",
            password:""
            }}
            onSubmit={async (values) => {
                setLoading(true)
                var result = await authenticateUser(values, setAuthentication);
                if(!result.success) {
                    setLoginErrors(result.errors)
                    setModalShow(true)
                    setLoading(false)
                }
            }}
            validationSchema={schema}>
            {({
            errors,
            touched,
            handleBlur,
            handleChange,
            isSubmitting,
            values,
            handleSubmit
            }) => (
            <Card className="ran-card">
                <Card.Header className="ran-card-header text-white">LOGIN</Card.Header>
                <Card.Body>
                <Card.Title>Welcome back!</Card.Title>
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group md="4" controlId="validateFormUsername" className="mt-3">
                            <Form.Label>Username</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                type="text"
                                placeholder="Username"
                                name="username"
                                value={values.username}
                                onChange={handleChange}
                                isInvalid={!!errors.username}
                                />
                                <Form.Control.Feedback type="invalid">
                                {errors.username}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group md="4" controlId="validateFormPassword" className="mt-3">
                            <Form.Label>Password</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                type="password"
                                placeholder="Password"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                isInvalid={!!errors.password}
                                />
                                <Form.Control.Feedback type="invalid">
                                {errors.password}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <div className="forgot-password text-center mt-2">
                            <Link to="/forgot-password">Forgot Password?</Link>
                        </div>
                        <Form.Group  className="mt-4 mb-0">
                            <Button disabled={isLoading} variant="primary" type="submit">
                                <FaSignInAlt /> LOGIN
                            </Button>
                            <Link className="btn btn-warning btn" to="/register" style={{float: "right"}}>REGISTER</Link>
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
            )}
        </Formik>
        <ErrorModal
            errors={loginErrors}
            show={modalShow}
            onHide={() => setModalShow(false)}
        />
        </>
    )
}

export default Login
