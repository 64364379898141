import React from 'react'
import { Row } from 'react-bootstrap'
import ReactLoading from 'react-loading';

const Bubbles = () => {
    return (
        <div>
            <Row>
                <ReactLoading type={"bubbles"} color={"orange"} /> 
                <ReactLoading type={"bubbles"} color={"orange"} /> 
                <ReactLoading type={"bubbles"} color={"orange"} /> 
                <ReactLoading type={"bubbles"} color={"orange"} /> 
                <ReactLoading type={"bubbles"} color={"orange"} /> 
                <ReactLoading type={"bubbles"} color={"orange"} /> 
                <ReactLoading type={"bubbles"} color={"orange"} /> 
                <ReactLoading type={"bubbles"} color={"orange"} /> 
                <ReactLoading type={"bubbles"} color={"orange"} /> 
                <ReactLoading type={"bubbles"} color={"orange"} /> 
                <ReactLoading type={"bubbles"} color={"orange"} /> 
                <ReactLoading type={"bubbles"} color={"orange"} /> 
            </Row>
        </div>
    )
}

export default Bubbles
