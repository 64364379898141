import React from 'react'

const HorizontalAdPreview = () => {
    return (
        <>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8206114193109226"
                crossorigin="anonymous"></script>
            {/* Horizontal Ad Preview */}
            <ins className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-8206114193109226"
                data-ad-slot="1035900449"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
        </>
    )
}

export default HorizontalAdPreview
