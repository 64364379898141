import React from 'react'
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import { useState, useEffect } from 'react'
import { getUserBasicInfo, requestLogout } from '../../helpers/APIHelper'
import { Table, Button } from 'react-bootstrap'
import { NavLink, Link } from 'react-router-dom'
import { FaChevronCircleDown, FaSignOutAlt } from "react-icons/fa";
import Bubbles from '../loading/Bubbles'
import { useLocation } from 'react-router'

const UserPanel = ({  setAuthentication }) => {

    let location = useLocation();
    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState({
        data : {}
    })

    useEffect(() => {
        async function fetchData() {
             await fetchInitialData();
        }
        
        fetchData();
    }, []);

    const fetchInitialData = async () => {
        var result = await getUserBasicInfo(setAuthentication);
        if(result !== null) {
            if(result.status === 200) {
                setData(result.data)
                setLoading(false)
            }
        }
        
    }

    const onLogout = async ()  => {
        setLoading(true);
        var result = requestLogout(setAuthentication);
        if(!result) {
            setLoading(false); 
        }
    }

    return (
        <Card className="ran-card">
            <Card.Header className="ran-card-header text-white">USER PANEL
                <Button onClick={onLogout} size="sm" variant="danger" style={{ float: "right"}}><FaSignOutAlt /> LOGOUT</Button>
            </Card.Header>
            <Card.Body>
            {
                isLoading ? ( <Bubbles /> )
                :
                    (<>
                    
                    <Table bordered variant="dark">
                    <tbody className="text-center">
                        <tr>
                            <td>Username</td>
                            <td><b>{data.username}</b></td>
                        </tr>
                        <tr>
                            <td>{data.premiumPointsName}</td>
                            <td><b id="premiumPoints">{data.premiumPoints}</b></td>
                        </tr>
                        <tr>
                            <td>{data.gamePointsName}</td>
                            <td><b id="gamePoints">{data.gamePoints}</b></td>
                        </tr>
                    </tbody>
                    </Table>
                    <ListGroup>
                        <ListGroup.Item className="user-panel-item">
                            <NavLink tag={Link} to="/account" className={`${location.pathname === '/account' ? 'user-panel-item-menu-active': ''}`}><FaChevronCircleDown /> &nbsp; VIEW ACCOUNT</NavLink>
                        </ListGroup.Item>
                        <ListGroup.Item className="user-panel-item">
                            <NavLink tag={Link} to="/change-password" className={`${location.pathname === '/change-password' ? 'user-panel-item-menu-active': ''}`}><FaChevronCircleDown /> &nbsp; CHANGE PASSWORD</NavLink>
                        </ListGroup.Item>
                        <ListGroup.Item className="user-panel-item">
                            <NavLink tag={Link} to="/change-pin" className={`${location.pathname === '/change-pin' ? 'user-panel-item-menu-active': ''}`}><FaChevronCircleDown /> &nbsp; CHANGE PIN CODE</NavLink>
                        </ListGroup.Item>
                        <ListGroup.Item className="user-panel-item">
                            <NavLink tag={Link} to="/forgot-pin" className={`${location.pathname === '/forgot-pin' ? 'user-panel-item-menu-active': ''}`}><FaChevronCircleDown /> &nbsp; FORGOT PIN CODE</NavLink>
                        </ListGroup.Item>
                        <ListGroup.Item className="user-panel-item">
                            <NavLink tag={Link} to="/change-email" className={`${location.pathname === '/change-email' ? 'user-panel-item-menu-active': ''}`}><FaChevronCircleDown /> &nbsp; CHANGE EMAIL</NavLink>
                        </ListGroup.Item>
                        <ListGroup.Item className="user-panel-item">
                            <NavLink tag={Link} to="/helpdesk" className={`${location.pathname === '/helpdesk' ? 'user-panel-item-menu-active': ''}`}><FaChevronCircleDown /> &nbsp; HELPDESK</NavLink>
                        </ListGroup.Item>
                        <ListGroup.Item className="user-panel-item">
                            <NavLink tag={Link} to="/gametime-gamepoints" className={`${location.pathname === '/gametime-gamepoints' ? 'user-panel-item-menu-active': ''}`}><FaChevronCircleDown /> &nbsp; GAMETIME TO GAMEPOINTS</NavLink>
                        </ListGroup.Item>
                        <ListGroup.Item className="user-panel-item">
                            <NavLink tag={Link} to="/pilot-mode" className={`${location.pathname === '/pilot-mode' ? 'user-panel-item-menu-active': ''}`}><FaChevronCircleDown /> &nbsp; PILOT MODE</NavLink>
                        </ListGroup.Item>
                        {/* <ListGroup.Item className="user-panel-item">
                            <NavLink tag={Link} to="/topup/records" className={`${location.pathname === '/topup/records' ? 'user-panel-item-menu-active': ''}`}><FaChevronCircleDown /> &nbsp; TOP UP RECORDS</NavLink>
                        </ListGroup.Item> */}
                        <ListGroup.Item className="user-panel-item">
                            <NavLink tag={Link} to="/ip-whitelists" className={`${location.pathname === '/ip-whitelists' ? 'user-panel-item-menu-active': ''}`}><FaChevronCircleDown /> &nbsp; IP WHITELISTS</NavLink>
                        </ListGroup.Item>
                        <ListGroup.Item className="user-panel-item">
                            <NavLink tag={Link} to="/shop/records" className={`${location.pathname === '/shop/records' ? 'user-panel-item-menu-active': ''}`}><FaChevronCircleDown /> &nbsp; SHOP RECORDS</NavLink>
                        </ListGroup.Item>
                    </ListGroup>
                    </>)
                    
            }
            </Card.Body>
        </Card>
    )
}

export default UserPanel
