import Card from 'react-bootstrap/Card'
import { userMaintenance } from '../../helpers/APIHelper'
import React from 'react';
import Button  from 'react-bootstrap/Button'
import { Formik } from 'formik'
import { Form, Alert  } from 'react-bootstrap'
import { InputGroup } from 'react-bootstrap'
import * as yup from "yup";
import ErrorModal from '../../components/modals/ErrorModal'
import Bubbles from '../loading/Bubbles';

const ChangePassword = ( {setAuthentication} ) => {
    
    const [modalShow, setModalShow] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false)
    const [isSuccess, setSuccess] = React.useState(false);

    const schema = yup.object().shape({
        currentPassword: yup.string().required("Current password is required."),
        newPassword: yup.string().required("New password is required."),
        confirmPassword: yup.string().required("Confirm password is required."),
        pin: yup.string().required("Pin code is required."),
    });

    return (
        <Card className="ran-card">
            <Card.Header className="ran-card-header text-white">CHANGE PASSWORD</Card.Header>
            <Card.Body>
                <>
                            {
                                isSuccess &&
                                <Alert variant="info" >
                                    Password has been successfully updated.
                                </Alert>
                            }
                             <Formik
                                initialValues={{
                                    currentPassword: "",
                                    newPassword:"",
                                    confirmPassword: "",
                                    pin:""
                                }}
                                onSubmit={async (values, {resetForm}) => {
                                    setLoading(true)
                                    setSuccess(false)
                                    
                                    var result = await userMaintenance('PASSWORD', values, setAuthentication);
                                    if(result !== null || result !== undefined){
                                        if(result.success) {
                                            setLoading(false)
                                            setSuccess(true)
                                            resetForm({
                                                currentPin: "",
                                                newPin:"",
                                                confirmPin: "",
                                                currentPassword:""
                                            })
                                        }
                                        else{
                                            // not found or bad request.
                                            if(result.status === 404 || result.status === 400){
                                                setModalShow(true)
                                                setErrors(result.errors)
                                                setLoading(false)
                                            }
                                            // do nothing if unauthorize.
                                        }
                                    }
                                }}
                                validationSchema={schema}>
                                {({
                                errors,
                                touched,
                                handleBlur,
                                handleChange,
                                isSubmitting,
                                values,
                                handleSubmit
                                }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Form.Group md="4" controlId="validateFormCurrentPassword">
                                            <Form.Label>Current Password</Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control
                                                type="password"
                                                placeholder="Current Password"
                                                name="currentPassword"
                                                value={values.currentPassword}
                                                onChange={handleChange}
                                                isInvalid={!!errors.currentPassword}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                {errors.currentPassword}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group md="4" controlId="validateFormPin" className="mt-2">
                                            <Form.Label>PIN Code</Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control
                                                type="password"
                                                placeholder="PIN Code"
                                                name="pin"
                                                value={values.pin}
                                                onChange={handleChange}
                                                isInvalid={!!errors.pin}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                {errors.pin}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group md="4" controlId="validateFormNewPassword" className="mt-2">
                                            <Form.Label>New Password</Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control
                                                type="password"
                                                placeholder="New Password"
                                                name="newPassword"
                                                value={values.newPassword}
                                                onChange={handleChange}
                                                isInvalid={!!errors.newPassword}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                {errors.newPassword}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group md="4" controlId="validateFormConfirmPassword" className="mt-2">
                                            <Form.Label>Confirm New Password</Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control
                                                type="password"
                                                placeholder="Confirm Password"
                                                name="confirmPassword"
                                                value={values.confirmPassword}
                                                onChange={handleChange}
                                                isInvalid={!!errors.confirmPassword}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                {errors.confirmPassword}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        {
                                            isLoading && 
                                            (
                                                <div className="mt-3">
                                                   <Bubbles />
                                                </div>
                                            )
                                        }
                                        <Form.Group  className="mt-4 mb-0">
                                            <Button  disabled={isLoading} type="submit" variant="warning" className="btn-danger text-white" style={{float:"right"}}>
                                                            {
                                                                isLoading ? 'PROCESSING' : 'CHANGE PASSWORD'
                                                            }
                                            </Button>
                                        </Form.Group>
                                </Form>
                                )}
                            </Formik>
                            <ErrorModal
                                errors={errors}
                                show={modalShow}
                                onHide={() => setModalShow(false)}/>
                        </>
            </Card.Body>
        </Card>
    )
}

export default ChangePassword
