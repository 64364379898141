import React from 'react'
import Pagination from 'react-bootstrap/Pagination'

const PaginationButton = ( {data, setPageNumber} ) => {

    const onSelectedItem = (pageNumber) => {
        setPageNumber(pageNumber)
    }

    let items = [];

    for (let number = 1; number <= data.totalPages; number++) {
        items.push(
          <Pagination.Item key={number} onClick={() => onSelectedItem(number)} active={data.pageIndex === number}>
            {number}
          </Pagination.Item>,
        );
      }

    return (
        <Pagination>{items}</Pagination>
    )
}

export default PaginationButton
