import React from 'react'
import Card from 'react-bootstrap/Card'

const Trailer = () => {

    return (
        <Card className="ran-card">
            <Card.Header className="ran-card-header text-white">RAN REUNITED TRAILER 2023</Card.Header>
            <Card.Body>
                <iframe style={{width:"100%"}} height="400" src="https://www.youtube.com/embed/Saelb1Wn2ok?si=n6oFVp_4dQ94cYBm" title="RAN REUNITED TRAILER 2023" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                {/* <img src="https://i.ibb.co/wyr2KHH/top-pk-site2.png" alt="logo" width="100%" /> */}
            </Card.Body>
        </Card>
    )
}

export default Trailer
