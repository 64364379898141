import Card from 'react-bootstrap/Card'
import { createWhitelist, getWhitelists } from '../../helpers/APIHelper'
import React from 'react';
import Button  from 'react-bootstrap/Button'
import ErrorModal from '../modals/ErrorModal'
import Bubbles from '../loading/Bubbles';
import { Formik } from 'formik'
import { Form, Alert, Table, InputGroup  } from 'react-bootstrap'
import * as yup from "yup";
import { FaPlusCircle } from "react-icons/fa";
import $ from 'jquery'

const Whitelist = ( {setAuthentication} ) => {
    
    const [modalShow, setModalShow] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const [isLoading, setLoading] = React.useState(true)
    const [isSuccess, setSuccess] = React.useState(false);

    const [isAdd, setAdd] = React.useState(true)

    const schema = yup.object().shape({
        pinCode: yup.string().required("Pin code is required."),
        ipAddress: yup.string().required("IP Address is required."),
    });

    const [data, setData] = React.useState({
        whitelists : []
    })

     React.useEffect(() => {
        async function fetchData() {
             await fetchInitialData();
        }
        fetchData();
    }, []);

    const fetchInitialData = async () => {
        var result = await getWhitelists(setAuthentication);
        if(result !== null) {
            if(result.status === 200) {
                setData(result.data)
                setLoading(false)
            }
        }
    }

    const onDelete = (item) => {
        setAdd(false);
        setSuccess(false);
        // $("[name='ipAddress']").val(item.ipAddress);
        $("[name='pinCode']").focus();
    }

    const onAdd = () => {
        setAdd(true);
        setSuccess(false);
        $("[name='pinCode']").focus();
    }

    return (
        <Card className="ran-card">
            <Card.Header className="ran-card-header text-white">IP WHITELISTS</Card.Header>
            <Card.Body>
                {
                    isLoading ? 
                    (
                        <div className="mt-3">
                            <Bubbles />                                                                                
                        </div>
                    )
                    :
                    (
                    <div className="p-2">
                        {
                            isSuccess && isAdd &&
                            <Alert variant="info" className="text-center">
                                IP Address successfully added.
                            </Alert>
                        }
                        {
                            isSuccess && !isAdd &&
                            <Alert variant="info" className="text-center">
                                IP Address successfully deleted.
                            </Alert>
                        }
                        <Alert variant="warning" className="text-center">
                            Only <b>WHITELISTED</b> IP Addresses can be used to login your account. Get your IP Address here <b>www.ipchicken.com</b> or <b>www.whatismyipaddress.com</b>. Maximum of <b>5</b> IP Addresses.
                        </Alert>
                        {
                            data.length > 0 &&
                            <Table striped bordered hover variant="dark" responsive>
                                <thead>
                                    <tr>
                                        <th>IP Address</th>
                                        <th>Date Created</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                   { data.map((item, index) => 
                                     <tr key={index}>
                                        <td>{item.ipAddress}</td>
                                        <td>{item.fomattedCreatedDate}</td>
                                        <td>
                                        <button onClick={() => onDelete(item)}  type="button" className="btn btn-sm btn-primary">Delete</button>
                                        </td>
                                    </tr>
                                   )}
                                </tbody>
                        </Table>
                        }
                        <Formik
                                initialValues={{
                                    pinCode:"",
                                    ipAddress:""
                                }}
                                onSubmit={async (values, {resetForm}) => {
                                    setLoading(true)
                                    setSuccess(false)

                                    values.delete = !isAdd;
                                    var result = await createWhitelist(values, setAuthentication);
                                        
                                    if(result !== null || result !== undefined){
                                        if(result.success) {
                                            setLoading(false)
                                            setSuccess(true)
                                            setData(result.data)
                                            resetForm({
                                                pinCode:"",
                                                ipAddress:""
                                            })
                                        }
                                        else{
                                            // not found or bad request.
                                            if(result.status === 404 || result.status === 400){
                                                setModalShow(true)
                                                setErrors(result.errors)
                                                setLoading(false)
                                            }
                                            // do nothing if unauthorize.
                                        }
                                    }
                                }}
                                validationSchema={schema}>
                                {({
                                errors,
                                touched,
                                handleBlur,
                                handleChange,
                                isSubmitting,
                                values,
                                handleSubmit
                                }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Button onClick={() => onAdd()} size="sm" variant="danger"><FaPlusCircle /> ADD</Button>
                                        <Form.Group md="4" controlId="validateFormPinCode" className="mt-2">
                                            <Form.Label>PIN Code</Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control
                                                type="password"
                                                placeholder="PIN Code"
                                                name="pinCode"
                                                value={values.pinCode}
                                                onChange={handleChange}
                                                isInvalid={!!errors.pinCode}
                                                autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                {errors.pinCode}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group md="4" controlId="validateFormIpAddress" className="mt-2">
                                            <Form.Label>IP Address</Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control
                                                type="text"
                                                placeholder="IP Address"
                                                name="ipAddress"
                                                value={values.ipAddress}
                                                onChange={handleChange}
                                                isInvalid={!!errors.ipAddress}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                {errors.ipAddress}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        {
                                            isLoading && 
                                            (
                                                <div className="mt-3">
                                                   <Bubbles />
                                                </div>
                                            )
                                        }
                                        {
                                            isAdd &&
                                            <Form.Group  className="mt-4 mb-0">
                                            <Button  disabled={isLoading} type="submit" variant="warning" className="btn-danger text-white" style={{float:"right", width: "100%"}}>
                                                            {
                                                                isLoading ? 'ADDING IP ADDRESS' : 'ADD IP ADDRESS'
                                                            }
                                            </Button>
                                            </Form.Group>
                                        }
                                        {
                                            !isAdd &&
                                            <Form.Group  className="mt-4 mb-0">
                                            <Button  disabled={isLoading} type="submit" variant="warning" className="btn-danger text-white" style={{float:"right", width: "100%"}}>
                                                            {
                                                                isLoading ? 'REMOVING IP ADDRESS' : 'REMOVE IP ADDRESS'
                                                            }
                                            </Button>
                                            </Form.Group>
                                        }
                                </Form>
                                )}
                        </Formik>
                        <ErrorModal
                            errors={errors}
                            show={modalShow}
                            onHide={() => setModalShow(false)}/>
                    </div>
                    )
                }
            </Card.Body>
        </Card>
    )
}

export default Whitelist
