import React from 'react'

const SquareAdPreview = () => {
    return (
        <>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8206114193109226"
                    crossorigin="anonymous"></script>
                {/* Square Ad Preview */}
                <ins className="adsbygoogle"
                    style={{ display: "block" }}
                    data-ad-client="ca-pub-8206114193109226"
                    data-ad-slot="7197467199"
                    data-ad-format="auto"
                    data-full-width-responsive="true"></ins>
                <script>
                    (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
        </>
    )
}

export default SquareAdPreview
