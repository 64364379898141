import React from 'react'
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import { useState, useEffect } from 'react'
import { getSchoolSmallLogo, getClassImage } from '../helpers/RANHelper.js'
import { Badge, Alert } from 'react-bootstrap'
import { getRankings } from '../helpers/APIHelper'
import Bubbles from './loading/Bubbles';

const Rankings = () => {
    
    const [pageTitle, setPageTitle] = useState('RANKINGS')
    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState({
        data: {
            topRanks: [],
            lastCachedDate: ''
        }
    })

    const [paramFilter, setParamFilter] = useState({
        type: 'pk',
        school: 'all',
        class: 'all',
        server: 'strife'
    })

    const [filterServers, setFilterServer] = useState([
        {
            id: 0,
            type: "strife",
            text: "strife",
            isSelected: true
        },
        {
            id: 1,
            type : "havoc",
            text: "havoc",
            isSelected: false
        },
        // {
        //     id: 2,
        //     type : "fury",
        //     text: "fury",
        //     isSelected: false
        // }
    ]);

    const updatePageTitle = () =>{
        let defaultTitle = 'RANKINGS';
        let selectedSchool = '';
        let selectedClass = '';

        var param = { ...paramFilter }

        if(param.school !== 'all')
            selectedSchool = param.school;
        
        if(param.class !== 'all')
        selectedClass = param.class;

        defaultTitle = defaultTitle + ' ' + param.server + ' SERVER ' + ' ' + param.type + ' ' +  selectedSchool + ' ' + selectedClass;
        setPageTitle(defaultTitle);
    }

    useEffect(() => {
        async function fetchData() {
            await fetchInitialData();
        }

        fetchData();
        updatePageTitle();
    }, [paramFilter]);
    
    const fetchInitialData = async () => {
        setLoading(true)
        var result = await getRankings(paramFilter);
        if(result !== null) {
            setData({ data: result.data})
            setLoading(false)
        }
    }
    const [filterTypes, setFilterType] = useState([
        {
            id: 1,
            type: "level",
            text: "Level",
            isSelected: false
        },
        {
            id: 2,
            type : "gold",
            text: "Gold",
            isSelected: false
        },
        {
            id: 3,
            type : "pk",
            text: "PK",
            isSelected: false
        },
        {
            id: 4,
            type : "resu",
            text: "Resu",
            isSelected: false
        }
        ,
        {
            id: 5,
            type : "gvg",
            text: "GVG",
            isSelected: false
        }
    ]);

    const [filterSchools, setFilterSchool] = useState([
        {
            id: 1,
            type: "sg",
            text: "Sacred Gate",
            isSelected: false
        },
        {
            id: 2,
            type : "mp",
            text: "Mystic Peak",
            isSelected: false
        },
        {
            id: 3,
            type : "phx",
            text: "Phoenix",
            isSelected: false
        },
        {
            id: 4,
            type : "all",
            text: "All Schools",
            isSelected: false
        }
    ]);

    const [filterClasses, setFilterClass] = useState([
        {
            id: 1,
            type: "brawler",
            text: "Brawler",
            isSelected: false
        },
        {
            id: 2,
            type : "swordsman",
            text: "Swordsman",
            isSelected: false
        },
        {
            id: 3,
            type : "archer",
            text: "Archer",
            isSelected: false
        },
        {
            id: 4,
            type : "shaman",
            text: "Shaman",
            isSelected: false
        },
        // {
        //     id: 5,
        //     type : "extreme",
        //     text: "extreme",
        //     isSelected: false
        // },
        // {
        //     id: 6,
        //     type : "gunner",
        //     text: "gunner",
        //     isSelected: false
        // },
        {
            id: 5,
            type : "all",
            text: "All Classes",
            isSelected: false
        }
    ]);

    const selectUnselectFilter = async (id, type, state, data) => {
        let unSelectFilter = data.map((filter) => filter.id !== id ? { ...filter, isSelected: false } : filter)
        state(unSelectFilter);

        state(unSelectFilter.map((filter) => filter.id === id ? { ...filter, isSelected: true } : filter))
        
        var param = { ...paramFilter }

        if (data === filterServers) {
            param.server = type;
            setParamFilter(param)
        }

        if(data === filterTypes) {
            param.type = type;
            setParamFilter(param)
        }
        if(data === filterSchools) {
            param.school = type;
            setParamFilter(param)
        }
        if(data === filterClasses) {
            param.class = type;
            setParamFilter(param)
        }
    }



    return (
       <Card className="ran-card">
            <Card.Header className="ran-card-header">{pageTitle}</Card.Header>
            <Card.Body>
                <div className="text-center mb-2">
                    <div className="mb-2">
                        <Badge bg="primary" text="white">SERVER</Badge>
                    </div>
                    <div className="btn-group rankings-btn-group btn-group-sm mb-4" role="group">
                        {
                            filterServers.map((filterServer, index) =>
                                <button key={index} onClick={() => selectUnselectFilter(filterServer.id, filterServer.type, setFilterServer, filterServers)}  type="button" className={`btn btn-${filterServer.isSelected ? 'danger': 'primary'}`}> {filterServer.text} </button>
                        )}
                    </div>
                    <div className="mb-2">
                        <Badge bg="warning" text="dark">RANKING TYPE</Badge>
                    </div>
                    <div className="btn-group rankings-btn-group btn-group-sm mb-4" role="group">
                        {
                            filterTypes.map((filterType, index) =>
                                <button key={index} onClick={() => selectUnselectFilter(filterType.id, filterType.type, setFilterType, filterTypes)}  type="button" className={`btn btn-${filterType.isSelected ? 'danger': 'primary'}`}> {filterType.text} </button>
                        )}
                    </div>

                    <div className="mb-2">
                        <Badge bg="info" text="white">SCHOOL</Badge>
                    </div>
                    <div className="btn-group rankings-btn-group btn-group-sm mb-4" role="group">
                        {
                            filterSchools.map((filterSchool, index) =>
                                <button key={index} onClick={() => selectUnselectFilter(filterSchool.id, filterSchool.type, setFilterSchool, filterSchools)}  type="button" className={`btn btn-${filterSchool.isSelected ? 'danger': 'primary'}`}> {filterSchool.text} </button>
                        )}
                    </div>
                    <div className="mb-2">
                        <Badge bg="success" text="white">CLASS</Badge>
                    </div>
                    <div className="btn-group rankings-btn-group btn-group-sm mb-4" role="group">
                        {
                            filterClasses.map((filterClass, index) =>
                                <button key={index} onClick={() => selectUnselectFilter(filterClass.id, filterClass.type, setFilterClass, filterClasses)}  type="button" className={`btn btn-${filterClass.isSelected ? 'danger': 'primary'}`}> {filterClass.text} </button>
                        )}
                    </div>
                </div>
                <div className="rankings-list p-3">
                    {
                        isLoading ? 
                        (
                           <Bubbles />
                        )
                        : (<>
                        <Alert variant="info" className="text-center">
                            <span className="text-dark">Data are cached every one hour. Last cached: <b>{data.data.lastCachedDate}</b></span>
                        </Alert>
                        <ListGroup>
                            { data.data.topRanks.map((data, index) => 
                                <ListGroup.Item key={index} className="rankings-item">
                                    {
                                        paramFilter.type !== 'gvg' &&
                                        <div className="d-flex">
                                            <img src={getClassImage(data.chaClass)} className="rounded" alt={data.class} />
                                        </div>
                                    }
                                    
                                    <div className="rankings-item-body">
                                        <div className="name"><img src={getSchoolSmallLogo(data.chaSchool)} alt={data.schoolName} />&nbsp; {data.chaName} </div>
                                        {
                                            paramFilter.type === 'level' && <div className="info"><span>Level: <b>{data.chaLevel}</b></span> | <span>Level Up Date: <b>{data.chaLastLevelUpDate}</b></span></div>
                                        }
                                        {
                                            paramFilter.type === 'gold' && <div className="info"><span>Gold: <span style={{color: "#ffc107", fontWeight: "bold"}}><b>{data.gold}</b></span></span></div>
                                        }
                                        {
                                            paramFilter.type === 'pk' && 
                                            <div className="info">
                                                <span>PK Kills: <span style={{color: "green", fontWeight: "bold"}}><b>{data.pkWin}</b></span> | PK Death:  <span style={{color: "#dc3545", fontWeight: "bold"}}><b>{data.pkLoss}</b></span>
                                                </span>
                                            </div>
                                        }
                                        {
                                            paramFilter.type === 'resu' && 
                                            <div className="info">
                                                <span>PK Resu: <span style={{color: "green", fontWeight: "bold"}}><b>{data.pkResu}</b></span> | PK Death:  <span style={{color: "#dc3545", fontWeight: "bold"}}><b>{data.pkLoss}</b></span>
                                                </span>
                                            </div>
                                        }
                                        {
                                            paramFilter.type === 'gvg' && 
                                            <div className="info">
                                                <span>Score: <span style={{color: "green", fontWeight: "bold"}}><b>{data.pkWin}</b></span> | Guild Master:  <span style={{fontWeight: "bold"}}><b>{data.guildMaster}</b></span> | Members:  <span style={{fontWeight: "bold"}}><b>{data.memberCount}</b></span>
                                                </span>
                                            </div>
                                        }
                                    </div>
                                <div className="text-white">
                                        <Badge bg="danger" text="white">{index + 1}</Badge>
                                </div>
                                </ListGroup.Item>
                            )}
                        </ListGroup>
                         </>)
                    }
                </div>
            </Card.Body>
        </Card>
    )
}

export default Rankings
