import Card from 'react-bootstrap/Card'
import { userMaintenance, getUserBasicInfo } from '../../helpers/APIHelper'
import React from 'react';
import Button  from 'react-bootstrap/Button'
import ErrorModal from '../../components/modals/ErrorModal'
import Bubbles from '../loading/Bubbles';
import { Formik } from 'formik'
import { Form, Alert  } from 'react-bootstrap'
import { InputGroup } from 'react-bootstrap'
import * as yup from "yup";

const PilotMode = ( {setAuthentication} ) => {
    
    const [modalShow, setModalShow] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const [isLoading, setLoading] = React.useState(true)
    const [isSuccess, setSuccess] = React.useState(false);

    const schema = yup.object().shape({
        pin: yup.string().required("Pin code is required.")
    });

    const [data, setData] = React.useState({
        data : {
            pilotMode : false
        }
    })

     React.useEffect(() => {
        async function fetchData() {
             await fetchInitialData();
        }
        fetchData();
    }, []);

    const fetchInitialData = async () => {
        var result = await getUserBasicInfo(setAuthentication);
        if(result !== null) {
            if(result.status === 200) {
                setData(result.data)
                setLoading(false)
            }
        }
    }

    return (
        <Card className="ran-card">
            <Card.Header className="ran-card-header text-white">PILOT MODE</Card.Header>
            <Card.Body>
                {
                    isLoading ? 
                    (
                        <div className="mt-3">
                            <Bubbles />                                                                                
                        </div>
                    )
                    :
                    (
                    <div className="p-2">
                        {
                            isSuccess &&
                            <Alert variant="info" className="text-center">
                                Pilot Mode has been successfully updated.
                            </Alert>
                        }
                        <Alert variant="primary" className="text-center">
                            PILOT MODE: &nbsp;
                                {
                                    data.pilotMode && 
                                    <b>ENABLED</b>
                                }
                                {
                                    !data.pilotMode && 
                                    <b>DISABLED</b>
                                }
                        </Alert>   
                        <Alert variant="warning" className="text-center">
                            When <b>Pilot Mode</b> is enabled, your character will not be able to drop items, trade to other characters and buy items in the item shop. <b>Re-login</b> ingame your account after updating the Pilot Mode settings.
                        </Alert>
                        <Formik
                                initialValues={{
                                    pin:""
                                }}
                                onSubmit={async (values, {resetForm}) => {
                                    setLoading(true)
                                    setSuccess(false)
                                    
                                    var result = await userMaintenance('PILOT_MODE', values, setAuthentication);
                                    if(result !== null || result !== undefined){
                                        if(result.success) {
                                            setLoading(false)
                                            setSuccess(true)
                                            setData({ pilotMode : result.data.pilotMode })
                                            // resetForm({
                                            //     currentPin: ""
                                            // })
                                        }
                                        else{
                                            // not found or bad request.
                                            if(result.status === 404 || result.status === 400){
                                                setModalShow(true)
                                                setErrors(result.errors)
                                                setLoading(false)
                                            }
                                            // do nothing if unauthorize.
                                        }
                                    }
                                }}
                                validationSchema={schema}>
                                {({
                                errors,
                                touched,
                                handleBlur,
                                handleChange,
                                isSubmitting,
                                values,
                                handleSubmit
                                }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Form.Group md="4" controlId="validateFormPin" className="mt-2">
                                            <Form.Label>PIN Code</Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control
                                                type="password"
                                                placeholder="PIN Code"
                                                name="pin"
                                                value={values.pin}
                                                onChange={handleChange}
                                                isInvalid={!!errors.pin}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                {errors.pin}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        {
                                            isLoading && 
                                            (
                                                <div className="mt-3">
                                                   <Bubbles />
                                                </div>
                                            )
                                        }
                                        <Form.Group  className="mt-4 mb-0">
                                            <Button  disabled={isLoading} type="submit" variant="warning" className="btn-danger text-white" style={{float:"right", width: "100%"}}>
                                                            {
                                                                isLoading ? 'PROCESSING' : 'UPDATE PILOT MODE'
                                                            }
                                            </Button>
                                        </Form.Group>
                                </Form>
                                )}
                            </Formik>         
                        <ErrorModal
                            errors={errors}
                            show={modalShow}
                            onHide={() => setModalShow(false)}/>
                    </div>
                    )
                }
            </Card.Body>
        </Card>
    )
}

export default PilotMode
